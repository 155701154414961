html.defaultTitle=SB2
#
new=новый
#
# navigation
#
html.navigation.dashboard=Главный экран
html.navigation.group.editor=Редактор
html.navigation.details=База деталей
html.navigation.processes=База ТП
html.navigation.coatings=База покрытий
html.navigation.rectifierProfiles=Профили выпрямителей
html.navigation.group.schema=Конфигуратор линии
html.navigation.positions=Список позиций
html.navigation.positionTypes=Типы позиций
html.navigation.transports=Транспорт
html.navigation.schema=Схема
html.navigation.properties=Настройки
html.navigation.group.others=Дополнительно
html.navigation.users=Пользователи
html.navigation.maintenance=Обслуживание
html.navigation.content=Текстовые блоки
html.navigation.queue=Подвески
html.navigation.reports=Отчеты
html.navigation.import=Импорт/экспорт
html.navigation.reboot=Перезагрузка
html.navigation.backup=Резервная копия
html.navigation.about=О программе
html.navigation.signIn=Вход
html.navigation.logOut=Выход
#
# common texts
#
button.edit=Редактировать
button.view=Просмотр
button.save=Сохранить
button.check=Проверить
button.cancel=Отмена
button.create=Создать
button.close=Закрыть
button.clone=Клонировать
button.add=Добавить
button.delete=Удалить
button.remove=Убрать
button.removeRevert=Вернуть
button.send=Отправить
button.download=Скачать
button.clear=Очистить
button.print=Распечатать
button.select=Выбрать
button.unlock=Разблокировать
button.turnOn=Включить
button.turnOff=Выключить
switch.on=Включено
switch.off=Выключено
boolean.yes=Да
boolean.no=Нет
remove.progress=Удаляется...
remove.done=Удалено
duration.seconds={0} сек
duration.minutes={0} мин
duration.hours={0} ч
restApi.403.toast.title=Недостаточно прав у аккаунта
restApi.403.toast.body=<div class="text-center">Вы вошли в свой аккаунт?<br><a href="/login" class="d-block my-2 text-light">Войти в аккаунт</a></div>
restApi.413.toast.title=Операция отклонена
restApi.413.toast.body=Слишком большой запрос. Попробуйте выбрать файл поменьше
restApi.404.toast.title=Неверный запрос
restApi.404.toast.body=Свяжитесь с администратором
restApi.500.toast.title=Внутренняя ошибка
restApi.500.toast.body=Свяжитесь с администратором
restApi.403=Не хватает прав для выполнения операции. Вы вошли в свой аккаунт?
restApi.genericError=Операцию осуществить не удалось!
#
# WebSocketClient
#
webSocket.closedNotice=Соединение с сервером потеряно. Данные могут отличаться от реальных
#
# toasts
#
toast.alert=Внимание!
toast.error=Ошибка!
toast.info=Уведомление
toast.success=Уведомление
#
# validation default texts
#
FloatCommaList=Значение должно содержать список чисел через запятую
IntCommaList=Значение должно содержать список чисел через запятую
#
# role labels
#
role.admin=админ
role.service=сервис
role.operator=оператор
role.tech=технолог
#
# dashboard page
#
dashboard.pageTitle=Главный экран
dashboard.h1=Главный экран
dashboard.action.addNewContainer=Создать подвеску
dashboard.action.viewContainers=Таблица подвесок
dashboard.action.switchToManual=Перейти в ручной режим
dashboard.action.switchToAuto=Перейти в автоматический режим
dashboard.action.switchToSemiAuto=Перейти в полуавтоматический режим
dashboard.action.switchToEmergency=Аварийный останов!
dashboard.action.switchToEmergency.confirm=Вы уверены?
dashboard.action.switchToService=Перейти в сервисный режим
dashboard.action.switchToPaused=Перейти в паузу
dashboard.action.switchToPlc=Управление с ПЛК
dashboard.modal.position.title=Позиция {0}
dashboard.modal.ao.title=АвтоОператор {0}
dashboard.modal.transfer.title=Трансфер {0}
dashboard.modal.container.title=Подвеска {0}
dashboard.listLive.caption=Статус подвесок
dashboard.listLive.pending=В ожидании
dashboard.listLive.working=В работе
dashboard.showFinished=Завершенные подвески
dashboard.showFinishedRest=к списку
dashboard.showLive=Показать текущие подвески
dashboard.listFinished.caption=Завершенные
#
# configure system page
#
configure.pageTitle=Настройка системы
#
# enums
#
rectifierProfile.type.BUILT_IN=встроенная программа
rectifierProfile.type.CURRENT=профиль тока
rectifierProfile.type.VOLTAGE=профиль напряжения
transport.type.AO=Автооператор
transport.type.TRANSFER=Трансфер
transport.state.DISABLED=выключен
transport.state.STOPPED=стоит
transport.state.TAKING=подъем
transport.state.PUTTING=опуск
transport.state.MOVING=движение
container.type.null=Любая
container.type.NOT_DEFINED=Любая
container.state.EMPTY=Пустой
container.state.PLANNED=Запланирована
container.state.LOADING=Загрузка
container.state.PROCESSING=В работе
container.state.WAITING=В ожидании
container.state.UNLOADING=Разгрузка
container.state.FINISHED=Закончена
container.state.BLOCKED=Заблокирована
#
# login page
#
login.pageTitle=Авторизация
login.returnToRoot=вернуться на главный экран
login.header=Авторизуйтесь для продолжения
login.form.username.label=Логин
login.form.username.placeholder=name@example.com
login.form.password.label=Пароль
login.form.password.placeholder=
login.form.rememberMe.label=Запомнить пользователя
login.form.submit=Вход
login.error.generic=Ошибка аутентификации
login.error.BadCredentials=Ошибка аутентификации. Для аутентификации необходимо указать верный логин и пароль.
#
# users page
#
userList.pageTitle=Список пользователей
userList.createUser=Добавить пользователя
userList.resetPassword=Сменить пароль
userEdit.createTitle=Создать пользователя
userEdit.editTitle=Редактирование пользователя
userEdit.setPasswordTitle=Установить пароль для пользователя
user.form.enabled=Включен?
user.form.enabledLabel=Включить возможность входа на сайт
user.form.username=Логин
user.form.usernameHelp=Под этим логином пользовать будет заходить в личный кабинет
user.form.password=Пароль
user.form.persNumber=Табельный номер
user.form.persNumberHelp=Табельный номер пользователя
user.form.position=Должность
user.form.positionHelp=
user.form.fullName=Полное имя
user.form.fullNameHelp=
user.form.roles=Роли
user.form.rolesHelp=Выбранные роли определяют возможности пользователя в личном кабинете
user.form.createCancelled=Пользователь не создан! Исправьте ошибки и попробуйте еще раз.
user.form.updateCancelled=Изменения не сохранены! Исправьте ошибки и попробуйте еще раз.
user.form.setPasswordCancelled=Пароль не обновлен! Исправьте ошибки и попробуйте еще раз.
#
# типы позиции
#
typesList.pageTitle=Список типов позиций
typesList.createType=Добавить позицию
typeEdit.createTitle=Создать новый тип позиции
typeEdit.editTitle=Редактирование типа позиции
typeEdit.deleteTitle=Удаление типа позиции
type.form.name=Название
type.form.nameHelp=Введите что-то, что характеризует позицию.
type.form.shortTitle=Короткое название (для схемы, опционально)
type.form.shortTitleHelp=
type.form.coatingCode=Шифр покрытия
type.form.coatingCodeHelp=Если указать "-", то эта позиция не будет учитываться в автоматической генерации шифра покрытия
type.form.maxCurrent=Максимальный ток (А)
type.form.maxCurrentHelp=Значение максимального тока для позиции, если применимо.
type.form.currentDensityByType=Скорость роста
type.form.currentDensity=Скорость роста ({0})
type.form.currentDensityHelp=Зависимость скорости роста толщины слоя от плотности тока
type.form.currentDensity.current=Плотность тока (A/дм²)
type.form.currentDensity.layerGrowthSpeed=Скорость роста слоя (мкм/мин)
type.form.visualTemplate=Шаблон для отображения
type.form.visualTemplateHelp=Используется для отображения позиции в отчетах
type.form.createCancelled=Тип не создан! Исправьте ошибки и попробуйте еще раз.
type.form.updateCancelled=Изменения не сохранены! Исправьте ошибки и попробуйте еще раз.
type.form.correctForm=Исправьте ошибки
type.form.deleteCancelled=Удаление невозможно!
type.error.hasPositions=есть позиции для этого типа
type.error.hasProcessActions=есть процессы, основанные на этом типе
type.error.hasDuplicatesInCurrentDensityValues=В таблицах скорости роста есть одинаковые значения плотности тока. Удалите дубликаты и попробуйте еще раз
#
# позиции
#
positionList.pageTitle=Список позиций
positionList.createPosition=Добавить позицию
positionList.createTitle=Создать новую позицию
positionList.editTitle=Редактирование позиции
positionList.deleteTitle=Удаление позиции
position.form.id=Номер позиции
position.form.idHelp=Порядковый номер на линии (1, 2, 3, ...)
position.form.line=Номер ряда
position.form.lineHelp=Номер ряда для позиции (1, 2)
position.form.containerType=Тип подвески
position.form.containerTypeHelp=
position.form.positionType=Тип позиции
position.form.positionTypeHelp=
position.form.rectifierNumber=Номер выпрямителя
position.form.rectifierNumberHelp=
position.form.aoLockZone=Зона блокировки автооператора
position.form.aoLockZoneHelp=
position.form.enabled=Включить позицию
position.form.enabledLabel=включена
position.form.enabledHelp=Выключенная позиция не участвует в работе
position.form.createCancelled=Позиция не создана! Исправьте ошибки и попробуйте еще раз.
position.form.updateCancelled=Изменения не сохранены! Исправьте ошибки и попробуйте еще раз.
position.form.deleteCancelled=Удаление не произошло! Исправьте ошибки и попробуйте еще раз.
position.error.idDuplicate=Такая позиция уже существует
position.error.cannotChangeLineBecauseOfTransfer=Нельзя менять линию - на эту позицию опирается трансфер.
position.error.cannotChangeLineBecauseOfAO=Нельзя менять линию - на эту позицию опирается АО.
position.error.cannotDeleteBecauseOfTransfer=Нельзя удалить позицию - на неё опирается трансфер.
position.error.cannotDeleteBecauseOfAO=Нельзя удалить позицию - на неё опирается АО.
#
# details
#
detailList.pageTitle=Детали
detailList.createDetail=Добавить деталь
detailList.createTitle=Создать новую деталь
detailList.editTitle=Редактирование детали
detailList.viewTitle=Деталь "{0}"
detailList.deleteTitle=Удаление детали
detailList.table.process=ТП
detailList.table.coating=Покрытие по КД
detailList.table.area=Площадь<br>(дм²)
detailList.table.weight=Вес<br>(кг)
detailList.table.width=Ширина<br>(мм)
detailList.table.length=Высота<br>(мм)
detailList.table.height=Длина<br>(мм)
detailList.table.diameter=Диаметр<br>(мм)
detail.form.deleteDetail=Удалить деталь
detail.form.deleteConfirm=Деталь будет удалена. Продолжить?
detail.form.code=Шифр детали
detail.form.codeHelp=
detail.form.name=Название
detail.form.nameHelp=
detail.form.type=Тип
detail.form.typeHelp=
detail.form.material=Материал
detail.form.materialHelp=
detail.form.area=Площадь (дм²)
detail.form.areaHelp=
detail.form.weight=Вес (кг)
detail.form.weightHelp=
detail.form.width=Ширина (мм)
detail.form.widthHelp=
detail.form.height=Высота (мм)
detail.form.heightHelp=
detail.form.length=Длина (мм)
detail.form.lengthHelp=
detail.form.diameter=Диаметр (мм)
detail.form.diameterHelp=
detail.form.info=Доп. информация
detail.form.infoHelp=
detail.form.coatings=Покрытие по КД
detail.form.coatingTable.code=Шифр
detail.form.coatingTable.search=Найти схему обработки по шифру или создать новое
detail.form.coatingTable.searchEmpty=Не нашлось подходящих схем. Нажмите кнопку "добавить" для создания новой схемы обработки с этим шифром
detail.form.coatingTable.addDuplicate=Такая схема обработки уже добавлена.
detail.form.createCancelled=Деталь не создана! Исправьте ошибки и попробуйте еще раз.
detail.form.updateCancelled=Изменения не сохранены! Исправьте ошибки и попробуйте еще раз.
detail.form.updateSuccess=Данные детали успешно сохранены.
#
# rectifier profile
#
rectifierProfileList.pageTitle=Профили выпрямителя
rectifierProfileList.createProfile=Добавить профиль
rectifierProfileEdit.createTitle=Создание нового профиля выпрямителя
rectifierProfileEdit.editTitle=Редактирование профиля выпрямителя
rectifierProfileEdit.deleteTitle=Удаление профиля выпрямителя
rectifierProfileEdit.stepOrderPlus=Переместить ниже
rectifierProfileEdit.stepOrderMinus=Переместить выше
rectifierProfile.form.createCancelled=Профиль не создан! Исправьте ошибки и попробуйте еще раз.
rectifierProfile.form.updateCancelled=Изменения не сохранены! Исправьте ошибки и попробуйте еще раз.
rectifierProfile.form.deleteCancelled=Удаление невозможно! Поправьте данные и попробуйте еще раз.
rectifierProfile.form.name=Название
rectifierProfile.form.nameHelp=
rectifierProfile.form.type=Тип профиля
rectifierProfile.form.typeHelp=
rectifierProfile.form.programNumber=Номер встроенной программы (1-10)
rectifierProfile.form.programNumberHelp=
rectifierProfile.form.timePoints=Опорные точки
rectifierProfile.form.timePointsHelp=
rectifierProfile.form.timePoint.interval=Длительность (сек)
rectifierProfile.form.timePoint.value=
rectifierProfile.form.timePoint.cycle=Старт цикла
rectifierProfile.form.cycledStep=Шаг зацикливания
rectifierProfile.form.cycledStepHelp=С этого шага программа начнет повторяться
rectifierProfile.plot.x=Время отсчета (сек)
rectifierProfile.plot.y.CURRENT=Значение (%)
rectifierProfile.plot.y.VOLTAGE=Значение (В)
rectifierProfile.error.noType=Необходимо выбрать тип профиля
rectifierProfile.error.cannotDeleteUsedInActions=Нельзя удалить профиль - он используется в процессах.
NotEmpty.rectifierProfileForm.timePoints=Нужно задать какие-то опорные точки
#
# transports
#
transportList.pageTitle=Список транспортов
transportList.createNew=Добавить транспорт
transportEdit.createTitle=Создать новый транспорт
transportEdit.editTitle=Редактирование транспорта
transportEdit.deleteTitle=Удаление транспорта
transport.form.createCancelled=Транспорт не создан! Исправьте ошибки и попробуйте еще раз.
transport.form.updateCancelled=Изменения не сохранены! Исправьте ошибки и попробуйте еще раз.
transport.form.deleteCancelled=Удаление отменено! Исправьте ошибки и попробуйте еще раз.
transport.form.type=Тип
transport.form.typeHelp=
transport.form.number=Номер
transport.form.numberHelp=
transport.form.enabled=Включить транспорт
transport.form.enabledLabel=включен
transport.form.enabledHelp=
transport.form.min=Минимальная позиция
transport.form.minHelp=
transport.form.max=Максимальная позиция
transport.form.maxHelp=
transport.form.damperTime=Демпферное время, мс
transport.form.damperTimeHelp=Дополнительный временной зазор, используется при согласованном движении двух АО или АО и трансфера
transport.error.maxHasDifferentLine=Для автооператора граничные позиции должны быть на одной линии
transport.error.aoOutOfMinMax=Текущая позиция находится вне указанной границы
transport.error.transferOutOfMinMax=Позиции не совпадают с текущим положением трансфера
transport.error.maxShouldBeOnOtherLineForTransfer=Для трансфера граничные позиции должны быть на разных линиях
transport.error.minIsNull=Необходимо указать минимальную позицию
transport.error.maxIsNull=Необходимо указать максимальную позицию
#
# processes
#
processList.pageTitle=Список процессов
processList.createPosition=Добавить процесс
processList.createTitle=Создание процесса
processList.editTitle=Редактирование процесса
processList.viewTitle=Тех. процесс "{0}"
processList.deleteTitle=Удаление процесса
processList.button.editActions=настроить шаги
process.form.actionCount=Кол-во операций
process.form.createCancelled=Процесс не создан! Исправьте ошибки и попробуйте еще раз.
process.form.updateCancelled=Изменения не сохранены! Исправьте ошибки и попробуйте еще раз.
process.form.deleteCancelled=Удаление отменено! Исправьте ошибки и попробуйте еще раз.
process.form.checkGraph.success=Проверка успешно пройдена
process.form.checkGraph.failure=Проверка завершилась ошибкой. Убедитесь, что все данные введены верно. Возможно, поможет выбор типа подвески.
process.view.dynamicLayersCount=Кол-во динамических слоев
process.form.name=Название
process.form.nameHelp=
process.form.code=Шифр
process.form.codeHelp=
process.form.containerType=Подвеска
process.form.containerTypeHelp=
process.form.coatingTable=Схемы обработки, использующие этот процесс
process.form.actions=Операции
setupActions.pageTitle=Настройка процесса
setupActions.addStep=Добавить шаг
setupActions.modeTimePointDown=передвинуть шаг вниз
setupActions.modeTimePointUp=передвинуть шаг вверх
setupActions.form.updateCancelled=Изменения не сохранены! Исправьте ошибки и попробуйте еще раз.
process.form.stepDown=передвинуть шаг вниз
process.form.stepUp=передвинуть шаг вверх
process.form.stepRemove=удалить шаг
processAction.form.order=Шаг
processAction.form.dynamicNoticeShort=Динамический шаг
processAction.form.dynamicNotice=Время будет рассчитываться исходя из толщины необходимого слоя, выбранной плотности тока и скорость нарастания
processAction.form.positionType=Позиция для обработки
processAction.form.positionTypeHelp=
processAction.form.rectifierProfile=Профиль выпрямителя
processAction.form.rectifierProfileHelp=
processAction.form.minCurrentDensity=Мин. плотность тока (A/дм²)
processAction.form.minCurrentDensityHelp=
processAction.form.maxCurrentDensity=Макс. плотность тока (A/дм²)
processAction.form.maxCurrentDensityHelp=
processAction.form.minDuration=Мин. продолжительность (сек)
processAction.form.minDurationHelp=
processAction.form.maxDuration=Макс. продолжительность (сек)
processAction.form.maxDurationHelp=
processAction.form.runoffTime=Время стекания (сек)
processAction.form.runoffTimeHelp=
processAction.error.maxDurationIsLessThenMinDuration=максимальное время не может быть меньше минимального
processAction.error.maxCurrentDensityIsLessThenMinCurrentDensity=максимальная плотность не может быть меньше минимальной
processAction.error.samePositionTypeAsBefore=нельзя ставить друг за другом ванны одного типа
processAction.error.valueIsMandatory=необходимо указать значение
processAction.error.rectifierProfileIsMandatory=необходимо выбрать профиль
NotNull.processForm.actions.positionType=необходимо выбрать позицию для обработки
ProcessPositionType.processForm.actions.positionType=специальные позиции нельзя явно указывать в процессе
NotNull.processForm.containerType=необходимо выбрать тип подвески
ProcessForm.hasLiveContainer=Изменение процесса запрещено - на его основе создан контейнер №{0}.
ProcessForm.warnLiveContainer=На основе процесса создан контейнер №{0}.
process.deleteForm.coatings=Схемы обработки, использующие этот процесс:
process.deleteForm.details=Детали, использующие этот процесс:
#
# schema editor
#
schemaEditor.pageTitle=Схема линии
schemaEditor.action.generate=Сгенерировать заново
schemaEditor.action.download=Скачать
schemaEditor.action.upload=Загрузить
schemaEditor.action.save=Сохранить схему
schemaEditor.saveNotice=Схема изменена. Нажмите "Сохранить схему", чтобы сохранить тот вариант схемы, который вы видите на экране
schemaEditor.messages.send=Послать
schemaEditor.messages.sendUpdatePositionMessage=Update Position
schemaEditor.messages.sendUpdateTransportMessage=Update Transport
schemaEditor.messages.sendUpdateContainerMessage=Update Container
#
# content blocks
#
contentBlockList.pageTitle=Текстовые блоки
contentBlockList.createBlock=Добавить блок
contentBlockList.createTitle=Новый блок
contentBlockList.editTitle=Редактирование блока
contentBlockList.deleteTitle=Удаление блока
contentBlock.form.createCancelled=Текстовый блок не создан! Исправьте ошибки и попробуйте еще раз.
contentBlock.form.updateCancelled=Изменения не сохранены! Исправьте ошибки и попробуйте еще раз.
contentBlock.form.deleteCancelled=Удаление отменено! Исправьте ошибки и попробуйте еще раз.
contentBlock.form.code=ID
contentBlock.form.codeHelp=
contentBlock.form.type=Тип
contentBlock.form.typeHelp=
contentBlock.form.title=Заголовок
contentBlock.form.titleHelp=
contentBlock.form.body=Содержимое
contentBlock.form.bodyHelp=
Pattern.contentBlockForm.code=ID может содержать только латинские символы, цифры, тире и точку, без пробелов.
#
# coatings
#
coatingList.pageTitle=Список схем обработки
coatingList.createCoating=Добавить схему
coatingList.createTitle=Новая схема обработки
coatingList.editTitle=Редактирование схемы обработки
coatingList.viewTitle=Cхема обработки "{0}"
coatingView.viewDetailList=Посмотреть список деталей
coatingList.deleteTitle=Удаление схемы обработки
coating.form.createCancelled=Схема не создана! Исправьте ошибки и попробуйте еще раз.
coating.form.updateCancelled=Изменения не сохранены! Исправьте ошибки и попробуйте еще раз.
coating.form.updateSuccess=Данные схемы обработки успешно сохранены.
coating.form.deleteCancelled=Удаление невозможно!
coating.form.codeOffer=Рекомендуемый шифр покрытия:
coating.form.codeOfferSelect=Использовать такой код
coating.form.code=Шифр
coating.form.codeHelp=
coating.form.process=Процесс
coating.form.processPlaceholder=Поиск процесса по шифру
coating.form.processHelp=
coating.form.thicknesses=Слои
coating.form.thicknessesHelp=
coating.form.thicknessTable.position=Ванна
coating.form.thicknessTable.thickness=Толщина (мкм)
coating.form.thickness=Толщина (мкм)
coating.table.details=Кол-во деталей
coating.table.viewDetailList=список деталей
NotNull.coatingForm.process=Надо указать процесс
NotEmpty.coatingForm.thickness=Нужно заполнить толщину покрытия
ListOfPositive.coatingForm.thickness=Значения толщины должны быть больше нуля
coatingDetails.pageTitle=Список деталей со схемой покрытия
coatingDetails.addDetails=Добавить детали
coatingDetails.searchDetailLabel=Поиск деталей для добавления
coatingDetails.searchDetailPlaceholder=введите шифр или название детали
coatingDetails.searchDetailEmpty=Деталей не найдено. Попробуйте другой шифр или название
coatingDetails.addSuccess=Деталь добавлена!
#
# system properties
#
properties.pageTitle=Настройки
properties.submit.updateCancelled=Изменения не сохранены! Исправьте ошибки и попробуйте еще раз.
properties.submit.saved=Изменения сохранены!
properties.form.lineTitle=Название линии (для отчетов)
properties.form.lineTitleHelp=
properties.form.lineNumber=Номер линии (для отчетов)
properties.form.lineNumberHelp=
properties.form.singleLine=Однорядная линия
properties.form.singleLineLabel=Да
properties.form.singleLineHelp=
properties.form.wrapLine=Добавить перенос в однорядной линии
properties.form.wrapLineLabel=Да
properties.form.wrapLineHelp=Позволяет отрисовать схему длинной однорядной линии в два ряда
properties.form.emptyContainerReturn=Возвращать пустую подвеску (линия с циркулирующими подвесками)
properties.form.emptyContainerReturnLabel=Да
properties.form.emptyContainerReturnHelp=Наличие флага означает, что линия с циркулирующими подвесками; если флаг не установлен - линия с выкатными тележками.
properties.form.automaticEmptyContainerReturn=Автоматически отправлять пустую подвеску в накопитель после разгрузки
properties.form.automaticEmptyContainerReturnLabel=Да
properties.form.automaticEmptyContainerReturnHelp=
properties.form.showSendToStorage=Показывать кнопку "Отправить в накопитель"
properties.form.showSendToStorageLabel=Да
properties.form.showSendToStorageHelp=Для собранной, но не отправленной в работу подвески, будет доступна кнопка "Отправить в накопитель"
properties.form.hideDashboardFormWhenWindowWidth=Мин. ширина экрана для формы
properties.form.hideDashboardFormWhenWindowWidthHelp=
properties.form.hideDashboardFormWhenWindowHeight=Мин. высота экрана для формы
properties.form.hideDashboardFormWhenWindowHeightHelp=
properties.form.positionsClockwise=Нумерация позиций по часовой стрелке (для двухрядной линии)
properties.form.positionsClockwiseLabel=Да
properties.form.positionsClockwiseHelp=
properties.form.currentDensities=Плотности тока
properties.form.currentDensitiesHelp=
properties.form.loadingPositionType=Зона загрузки
properties.form.loadingPositionTypeHelp=Начать загрузку подвески возможно только в позициях этой зоны
properties.form.unloadingPositionType=Зона выгрузки
properties.form.unloadingPositionTypeHelp=Разгрузка/выкат готовой подвески возможен только из позиций этой зоны
properties.form.bufferPositionType=Зона ожидания
properties.form.bufferPositionTypeHelp=Зона для отправки загруженных, но не взятых в работу подвесок, с целью освобождения зоны загрузки
properties.form.finishPositionTypes=Зоны финиша
properties.form.finishPositionTypesHelp=После окончания программы, подвески направляются в выбранные здесь зоны
properties.form.cylinderTypeTitle=Название
properties.form.cylinderTypeTitleHelp=
properties.form.cylinderArea=Макс. площадь (дм²)
properties.form.cylinderAreaHelp=
properties.form.cylinderLoadMaxKg=Грузоподъемность (кг)
properties.form.cylinderLoadMaxKgHelp=
properties.form.finishPositionLockTime1=Норматив выгрузки (сек)
properties.form.finishPositionLockTime1Help=
properties.form.suspensionTypeTitle=Название
properties.form.suspensionTypeTitleHelp=
properties.form.suspensionArea=Макс. площадь (дм²)
properties.form.suspensionAreaHelp=
properties.form.suspensionLoadMaxKg=Грузоподъемность (кг)
properties.form.suspensionLoadMaxKgHelp=
properties.form.finishPositionLockTime2=Норматив выгрузки (сек)
properties.form.finishPositionLockTime2Help=
properties.form.useTransportStatistic=Использовать статистику перемещения АО и трансферов при расчете программы
properties.form.useTransportStatisticLabel=Да
properties.form.useTransportStatisticHelp=
properties.form.suspensionTakeDelayFactor=Кратность времени стекания (сек)
properties.form.suspensionTakeDelayFactorHelp=Возможный выбор времени стекания при подъеме подвески ограничен вариантами [0..9]*[кратность]
properties.form.bufferFinishPenalty=Штраф за выгрузку подвески в накопитель
properties.form.bufferFinishPenaltyHelp=Количество штрафных секунд, которые добавляются к маршруту при выгрузке в накопитель вместо зоны выгрузки. Разумное значение: 0-600.
properties.form.waitInBufferAfterStartPenalty=Штраф за перенос подвески в накопитель после старта
properties.form.waitInBufferAfterStartPenaltyHelp=Количество штрафных секунд за использование накопителя между зоной загрузки и первой процессной ванной. Разумное значение 10-300.
properties.form.waitInBufferAfterStartFactor=Приоритет ожидания в накопителе после старта
properties.form.waitInBufferAfterStartFactorHelp=Коэффициент для расчета стоимости времени ожидания в накопителе. Чем меньше, тем охотнее будет использоваться зона. Разумное значение 0.1-1.
properties.form.waitInStartPositionFactor=Приоритет ожидания в позиции загрузки после старта
properties.form.waitInStartPositionFactorHelp=Коэффициент для расчета стоимости времени ожидания в зоне загрузки. Чем меньше, тем охотнее будет использоваться зона. Разумное значение 1-10.
properties.form.programGeneratorTimeQuantum=Зазор при раздвигании, мс
properties.form.programGeneratorTimeQuantumHelp=Дополнительный зазор времени, который используется при раздвигании команд. По умолчанию - 100.
properties.form.programGeneratorCheckDestinationTimeDelta=Задержка подъема, сек
properties.form.programGeneratorCheckDestinationTimeDeltaHelp=Интервал времени между проверкой доступности позиции назначения и началом подъема подвески для движения в эту позицию. По умолчанию - 3.
properties.form.programGeneratorDestinationNotifyTime=Интервал оповещения о выгрузке, сек
properties.form.programGeneratorDestinationNotifyTimeHelp=Время в секундах, за которое приходит оповещение о выгрузке в позиции назначения. По умолчанию - 300.
properties.form.timeoutPerActiveContainer=Коэффициент таймаута, мс
properties.form.timeoutPerActiveContainerHelp=Таймаут операций зависит от кол-ва активных контейнеров. По умолчанию - 3000.
properties.form.relocationPositions=Позиции для перехвата (номера позиций через запятую)
properties.form.relocationPositionsHelp=Используются при создании программ перемещения подвесок в автоматическом режиме: перемещение пустой или выполненной подвески между зонами ожидания, погрузки и выгрузки.
properties.form.relocationMaxWaitTime=Максимальное время перехвата, сек
properties.form.relocationMaxWaitTimeHelp=
properties.form.waitInRelocationPenalty=Штраф за ожидание в позиции перехвата
properties.form.waitInRelocationPenaltyHelp=
properties.form.commandLockTimeout=Таймаут ожидания выполненной команды, мс
properties.form.commandLockTimeoutHelp=Допустимое превышение фактического времени выполнения команды относительно расчетного. Используется, когда следом идет другая команда. При превышении этого значения система переходит в аварийный режим. Разумное значение 500-3000.
properties.form.programGeneratorUsePreviousProgram=По возможности использовать последнюю собранную программу
properties.form.programGeneratorUsePreviousProgramLabel=Да
properties.form.programGeneratorUsePreviousProgramHelp=
properties.form.programGeneratorFixNonProcessPositionOnProgramRebuilding=По возможности фиксировать ранее выбранные не процессные позиции (накопители, позиции выгрузки).
properties.form.programGeneratorFixNonProcessPositionOnProgramRebuildingLabel=Да
properties.form.programGeneratorFixNonProcessPositionOnProgramRebuildingHelp=
properties.form.programGeneratorEarlyAoShift=Заранее сдвигать мешающий движению АО.
properties.form.programGeneratorEarlyAoShiftLabel=Да
properties.form.programGeneratorEarlyAoShiftHelp=Есть два способа отодвинуть АО, если он мешает проезду второго АО: как можно раньше после выполения первым АО команды в результате которой он оказался на пути следования второго АО или непосредственно перед проездом второго АО. Актуально только для автоматического режима. 
#
# service status
#
service.status.UNKNOWN=Непонятный режим
service.status.AUTOMATIC=Автоматический режим
service.status.SEMIAUTOMATIC=Полуавтоматический режим
service.status.MANUAL=Ручной режим
service.status.PAUSED=Режим паузы
service.status.PLC=Режим управления с ПЛК
service.status.STOPPED=Аварийная остановка
service.status.programs=Программы: {0}
service.status.commands=Команды: {0}
service.status.finishTime=Окончание программ: {0}
#
# containers
#
containerEmptyList.pageTitle=Подвески на линии
containerEmptyList.createNew=Добавить подвеску
containerEdit.createTitle=Создание подвески
containerEdit.editTitle=Редактирование подвески {0}
containerEdit.deleteTitle=Удаление подвески {0}
container.table.type=Тип
container.table.state=Состояние
container.table.position=Позиция
container.table.creator=Кем создан
container.table.createDate=Дата создания
container.table.sendDate=Дата отправки
container.table.finishDate=Дата окончания
container.table.detailCount=Кол-во деталей
container.error.cannotChangeContainerDueState=В данном состоянии редактировать подвеску нельзя
container.error.cannotDeleteContainerDueState=В данном состоянии удалить подвеску нельзя
container.error.cannotDeleteContainerDueMode=Удалить эту подвеску можно только в ручном или аварийном режиме
container.error.positionOccupied=Эта позиция уже занята другой подвеской
container.form.type=Тип подвески
container.form.typeHelp=
container.form.state=Состояние
container.form.stateHelp=
container.form.position=Позиция
container.form.positionHelp=
container.form.createCancelled=Подвеска не создана! Исправьте ошибки и попробуйте еще раз.
container.form.updateCancelled=Изменения не сохранены! Исправьте ошибки и попробуйте еще раз.
container.form.deleteCancelled=Удаление отменено! Исправьте ситуацию и попробуйте еще раз.
container.form.saved=Изменения сохранены!
#
# container queue
#
queueList.pageTitle=Таблица подвесок
queueList.addNewContainer=Создать подвеску
queueList.addEmptyContainer=Создать пустую подвеску
queueList.tab.alive=На линии
queueList.tab.planned=Запланированные
queueList.tab.working=В работе
queueList.tab.finished=Завершенные
queueList.tab.table.id=№
queueList.tab.table.position=Поз.
queueList.tab.table.targetPosition=Кон.
queueList.tab.table.state=Статус
queueList.tab.table.coating=Схема обработки
queueList.tab.table.process=Процесс
queueList.tab.table.details=Детали
queueList.tab.table.createDate=Создан
queueList.tab.table.sendDate=Начат
queueList.tab.table.finishTime=Закончен
queueList.tab.table.actions=Действия
queueList.tab.table.loadMore=Загрузить больше записей
#
# create container
#
queueEdit.newContainer.pageTitle=Создание подвески
queueEdit.editContainer.pageTitle=Редактирование подвески {0}
containerForm.submitSuccess=Данные подвески сохранены!
containerForm.submitFailure=Данные не сохранены! Поправьте информацию и попробуйте снова
containerForm.editNotAvailable=Редактирование подвески в этом состоянии невозможно
containerForm.programEstimate=Время обработки
containerForm.form.type=Тип подвески
containerForm.form.typeHelp=
containerForm.form.process=Процесс
containerForm.form.processHelp=
containerForm.form.processPlaceholder=Поиск процесса по коду
containerForm.form.steps.name=Шаги процесса
containerForm.form.steps.hideNonDynamic=скрыть статичные
containerForm.form.steps.coating=Тип<br>покрытия
containerForm.form.steps.duration=Время<br>обработки (c)
containerForm.form.steps.currentDensity=Плотность<br>тока <span class="text-nowrap">(A/дм²)</span>
containerForm.form.steps.stepCurrent=Ток (A)
containerForm.form.steps.maxCurrent=Макс.<br>ток (A)
containerForm.form.coating=Схема обработки
containerForm.form.coatingHelp=
containerForm.form.coatingPlaceholder=Поиск схемы обработки
containerForm.form.coatingSearchEmpty=Не нашлось подходящих схем. Попробуйте что-нибудь другое
containerForm.form.selfArea=Площадь подвесов (дм²)
containerForm.form.selfAreaHelp=
containerForm.form.detailArea=Площадь деталей (дм²)
containerForm.form.detailAreaHelp=Площадь деталей вычисляется автоматически
containerForm.form.detailWeight=Вес деталей (кг)
containerForm.form.detailWeightHelp=
containerForm.form.details=Детали
containerForm.form.detailsTable.code=Шифр
containerForm.form.detailsTable.name=Название
containerForm.form.detailsTable.codeName=Шифр / название
containerForm.form.detailsTable.area=Площадь (дм²)
containerForm.form.detailsTable.amount=Кол-во
containerForm.form.detailsAdd=Добавить детали
containerForm.passedProgram.title=Пройденные обработки
containerForm.passedProgram.start=Время запуска:
containerForm.passedProgram.finish=Время окончания:
updateContainer.error.noAddedDetails=Нет добавленных деталей. Нажмите кнопку "Добавить деталь" для наполнения подвески деталями
updateContainer.error.programIsInProgress=Нельзя поменять процесс, пока программа выполняется
updateContainer.error.programIsDifferent=Программа подвески изменилась, откройте страницу редактирования подвески заново.
updateContainer.error.programIsNotByProcess=Текущую программу подвески невозможно изменить, пока она не закончится
updateContainer.error.wrongState=Состояние подвески не дает возможности его изменить
updateContainer.error.wrongCoatingForDetail=Деталь не может иметь выбранную схему обработки
updateContainer.error.coatingHasNoProcess=Для этой схемы обработки не определен тех. процесс
updateContainer.error.coatingProcessMismatch=Для этой схемы необходимо выбрать другой тех. процесс
updateContainer.error.containerOverloadKg=Подвеска перегружена. Максимальный вес - {0} кг.
updateContainer.error.containerOverloadArea=Подвеска перегружена. Максимальная площадь - {0} дм².
NotNull.containerForm.type=Нужно указать тип подвески
NotNull.containerForm.detailArea=Нужно указать площадь деталей
PositiveOrZero.containerForm.detailArea=Площадь деталей не должна быть отрицательной
PositiveOrZero.containerForm.detailWeight=Вес деталей не должен быть отрицательным
NotNull.containerForm.selfArea=Нужно указать площадь подвеса
PositiveOrZero.containerForm.selfArea=Площадь подвеса не должна быть отрицательной
NotNull.containerForm.process=Нужно указать процесс
ValidProcess.containerForm.process=Этот процесс не существует
NotNull.containerForm.coating=Нужно указать схему обработки
ValidCoating.containerForm.coating=Эта схема обработки не существует
Positive.containerForm.details.amount=Количество деталей должно быть больше 1
NotNull.containerForm.actions.currentDensity=Нужно указать плотность тока
NotNull.containerForm.actions.currentDensity.indexed=Нужно указать плотность тока на шаге {0}
Positive.containerForm.actions.currentDensity=Плотность тока должна быть больше нуля
Positive.containerForm.actions.currentDensity.indexed=Плотность тока на шаге {0} должна быть больше нуля
NotNull.containerForm.actions.currentValue=Нужно указать значение тока
NotNull.containerForm.actions.currentValue.indexed=Нужно указать значение тока на шаге {0}
Positive.containerForm.actions.currentValue=Значение тока должно быть больше нуля
Positive.containerForm.actions.currentValue.indexed=Значение тока на шаге {0} должно быть больше нуля
sendContainerForm.form.position=Позиция
sendContainerForm.form.positionHelp=
#
# view container
#
viewContainerPage.title=Подвеска {0}
viewContainerPage.stateLabel=Статус:
viewContainerPage.action.sendToLoad=Отправить на загрузку
viewContainerPage.action.sendToWork=Отправить в работу
viewContainerPage.action.cancelLoading=Отменить отправку
viewContainerPage.action.deleteContainer=Удалить подвеску
viewContainerPage.action.move=Передвинуть
viewContainerPage.action.finish=Разгрузка окончена
SendContainerToLoad.title=Отправить {0} на загрузку
SendContainerToLoad.noAvailablePositions=Нет позиций, доступных для загрузки. Отправка невозможна.
SendContainerToLoad.completeNotice=Подвеска отправлена на загрузку
SendContainerToWork.calculateButton=Рассчитать время
SendContainerToWork.confirmNotice=Подтвердите отправку {0}
SendContainerToWork.completeNotice=Подвеска отправлена в работу
SendContainerToWork.completeNoticeWithGenerator=Подвеска отправлена в работу. Проверено {0} из {1} возможных маршрутов ({2}%)
SendContainerToUnload.confirmNotice=Подтвердите разгрузку {0}
SendContainerToUnload.completeNotice=Подвеска отправлена на загрузку
CancelContainer.deleteTitle=Удаление подвески {0}
CancelContainer.cancelTitle=Отмена подвески {0}
CancelContainer.confirmBody.PLANNED=Данные подвески будут удалены вместе с подвеской
CancelContainer.confirmBody.LOADING=Подвеска перейдет в состояние "запланирована"
CancelContainer.confirmButton.PLANNED=Очистить подвеску
CancelContainer.confirmButton.LOADING=Отменить загрузку
CancelContainer.completeNotice=Подвеска отменена
FinishContainer.confirmNotice=Подвеска {0} разгружена?
FinishContainer.completeNotice=Работа с подвеской окончена
CancelStarting.confirmNotice=Отмена программы {0}
CancelStarting.confirmBody=Программа будет отменена и контейнер перейдет в состояние загрузки
CancelStarting.completeNotice=Программа отменена
CancelStarting.confirmButton=Отменить
#
# ProcessInputField
#
ProcessInputField.searchButtonTitle=выбор процесса
ProcessInputField.clearButtonTitle=очистить
ProcessInputField.gotoButtonTitle=открыть процесс в новом окне
ProcessInputField.searchTitle=Поиск процесса
ProcessInputField.searchPlaceholder=введите шифр или название процесса
ProcessInputField.searchEmpty=Подходящих процессов не найдено
ProcessInputField.searchError=Возникла ошибка в процессе поиска
ProcessInputField.table.code=Шифр
ProcessInputField.table.name=Название
ProcessInputField.countNotice=Показано процессов: {0} из {1}
#
# CoatingInputField
#
CoatingInputField.searchButtonTitle=выбор схемы обработки
CoatingInputField.clearButtonTitle=очистить
CoatingInputField.gotoButtonTitle=открыть схему в новом окне
CoatingInputField.searchTitle=Поиск схемы обработки
CoatingInputField.searchPlaceholder=введите шифр или название схемы
CoatingInputField.searchEmpty=Подходящих схем не найдено
CoatingInputField.table.code=Шифр
CoatingInputField.table.process=Процесс
CoatingInputField.countNotice=Показано схем обработки: {0} из {1}
#
# DetailSearch
#
DetailSearch.title=Поиск деталей по шифру
DetailSearch.searchPlaceholder=Введите код или название детали
DetailSearch.searchError=Возникла ошибка в процессе поиска
DetailSearch.searchEmpty=Подходящих деталей не найдено
DetailSearch.countNotice=Показано деталей: {0} из {1}
DetailSearch.table.code=Шифр / название
#
# Container widget
#
ContainerWidget.title.pin=Закрепить виджет на главном экране
ContainerWidget.label.process=Тех. процесс
ContainerWidget.label.processStep=Шаг тех. процесса
ContainerWidget.label.processDetails=Детали
ContainerWidget.label.position=Позиция
ContainerWidget.label.targetPosition=Кон. позиция
ContainerWidget.label.temperature=Температура
ContainerWidget.label.current=Ток
ContainerWidget.label.finishTime=Финиш
ContainerWidget.value.after=через {0}
ContainerWidget.action.goto=Подробнее
ContainerWidget.action.move=Переместить
ContainerWidget.action.toLoad=Отпр. на загрузку
ContainerWidget.action.toWork=Отпр. в работу
ContainerWidget.action.toUnload=Отпр. на разгрузку
ContainerWidget.action.finish=Разг. окончена
#
# BusinessLogicException
#
BusinessLogicException.currentDensityOutOfRange=Плотность тока {0} выходит за рамки возможного диапазона для ванны типа {1}. Измените плотность тока или отредактируйте таблицу плотностей тока для этого типа ванн.
BusinessLogicException.noAvailablePositions=Не найдено ни одной доступной позиции типа "{0}".
BusinessLogicException.noAvailableStorage=Не найдено свободного накопителя на линии. Попробуйте выполнить команду позже.
BusinessLogicException.cannotSwitchMode=Переключение в режим "{1}" невозможно (активных программ - {2}).
BusinessLogicException.incorrectMode=Невозможно осуществить действие в данном режиме работы.
BusinessLogicException.cannotCreateProgram=Не удалось сгенерировать программу обработки подвески.
BusinessLogicException.cannotCreateProgramTryLater=Не удалось сгенерировать программу обработки подвески. Попробуйте позже.
BusinessLogicException.targetPositionNotFound=Не найдено подходящей позиции для выгрузки подвески {0}
BusinessLogicException.programCreationTimeout=Превышено допустимое время генерации программы.
BusinessLogicException.noPathToPositionAtStep=Не удалось найти способ передвинуть подвеску в "{0}" на шаге {1} в процессе "{2}".
BusinessLogicException.incorrectContainerType=Тип оснастки не соответствует типу, указанному в тех. процессе
BusinessLogicException.positionLocked=Невозможно выполнить операцию: позиция {0} заблокирована
BusinessLogicException.containerHasNoActiveProgram=У контейнера нет активной программы
BusinessLogicException.cannotDoInThisMode=В текущем режиме операция недоступна
BusinessLogicException.noActivityNeeded=Дождитесь когда закончатся все активные команды для выполнения этого действия
BusinessLogicException.positionIsNotEmpty=Невозможно осуществить операцию: на позиции {0} находится подвеска {1}
BusinessLogicException.cNoContainerOnPosition=Нет контейнера на позиции {0}
BusinessLogicException.transportIsNotReady=Для данного действия все АО и трансферы должны быть без подвесок и в состоянии покоя
BusinessLogicException.transportPositionOutOfRange=Транспорт {0} (ID {1}) находится вне допустимых позиций {2} - {3}
BusinessLogicException.motionConflict=Операция отменена из-за возможного столкновения
BusinessLogicException.cloneAvailableForSingleProgramOnly=Можно клонировать только контейнер с одной процессной программой
BusinessLogicException.cannotCreateRelocationWay=Не удалось построить маршрут из позиции {0} в позицию {1}. Проверьте список промежуточных позиций для перемещений.
BusinessLogicException.operatorSolutionNeeded=Требуется вмешательство оператора: подвеска {0} в позиции {1} не может быть перезапущена т.к. это нарушает установленный техпроцесс.
#
# TransportPopover
#
TransportPopover.label.position=Позиция
TransportPopover.action.move=Переместить
TransportPopover.action.take=Поднять
TransportPopover.action.put=Поставить
#
# SendContainerToPosition
#
SendContainerToPosition.title=Передвинуть подвеску {0}
SendContainerToPosition.noAvailablePositions=Нет доступных пустых позиций, отправка невозможна.
SendContainerToPosition.completeNotice=Подвеска отправлена на позицию
#
# SendTransportToPosition
#
SendTransportToPosition.title=Передвинуть транспорт {0}
SendTransportToPosition.noAvailablePositions=Нет доступных пустых позиций, отправка невозможна.
SendTransportToPosition.completeNotice=Транспорт отправлен на позицию
#
# SendTransportTake
#
SendTransportTake.completeNotice=Задача транспорту отправлена
SendTransportTake.confirmNotice=Поднять транспорт {0}?
#
# SendTransportPut
#
SendTransportPut.completeNotice=Задача транспорту отправлена
SendTransportPut.confirmNotice=Поставить транспорт {0}?
#
# AliveList
#
AliveList.emptyListMessage=пусто
#
# ContainerListItem
#
ContainerListItem.startFrom=Запуск из поз.
ContainerListItem.position=Позиция
ContainerListItem.finishAfter=До окончания
ContainerListItem.overexposureText=Передержка!
ContainerListItem.duration=Время обработки:
ContainerListItem.contextMenu.doLock=Заблокировать
ContainerListItem.contextMenu.doUnlock=Разблокировать
ContainerListItem.contextMenu.doEdit=Редактировать
ContainerListItem.contextMenu.doMove=Переместить
ContainerListItem.contextMenu.doPrint=Распечатать
ContainerListItem.contextMenu.doReport=Отчет
ContainerListItem.contextMenu.doDelete=Удалить
ContainerListItem.contextMenu.doClone=Клонировать
ContainerListItem.contextMenu.doCancel=Отменить
ContainerListItem.contextMenu.doCancelStarting=Отменить
ContainerListItem.clickMenu.state=Статус
ContainerListItem.clickMenu.process=Тех. процесс
ContainerListItem.clickMenu.coating=Покрытие
ContainerListItem.clickMenu.duration=Время обработки
ContainerListItem.clickMenu.area=Площадь
ContainerListItem.clickMenu.areaValue={0} дм²
ContainerListItem.clickMenu.operator=Оператор
ContainerListItem.clickMenu.created=Время создания
ContainerListItem.clickMenu.programStart=Старт программы
ContainerListItem.clickMenu.programFinish=Финиш программы
ContainerListItem.clickMenu.startIn=Старт через (цикл.)
ContainerListItem.clickMenu.finishIn=До окончания
ContainerListItem.clickMenu.stepDone=Выполнено шагов
ContainerListItem.clickMenu.doLoad=Начать загрузку
ContainerListItem.clickMenu.doStart=Запуск
ContainerListItem.clickMenu.doSendToStorage=Отправить в накопитель
ContainerListItem.clickMenu.doUnload=Отправить на выгрузку
ContainerListItem.clickMenu.doFinish=Закончить
ContainerListItem.clickMenu.doDisband=Расформировать
ContainerListItem.clickMenu.current=Ток
ContainerListItem.clickMenu.voltage=Напряжение
ContainerListItem.clickMenu.temp=Температура
ContainerListItem.clickMenu.doView=Просмотр
ContainerListItem.clickMenu.positionNow=Тек. позиция
ContainerListItem.clickMenu.positionNext=След. позиция
ContainerListItem.clickMenu.positionEnd=Кон. позиция
#
# FinishedList 
#
FinishedList.emptyListMessage=пусто
FinishedList.forbiddenMessage=Нет доступа к списку завершенных контейнеров
#
# PositionPopover
#
PositionPopover.usesCurrent.yes=токовая
PositionPopover.usesCurrent.no=не токовая
PositionPopover.completePlcUnlockPosition=Запрос выполнен!
PositionPopover.positionOff=Позиция выключена!
PositionPopover.positionOn=Позиция включена!
PositionPopover.turnOnRectifierButton=Запуск ВП
#
# Report index
#
reportIndex.pageTitle=Отчеты по законченным подвескам
reportIndex.table.createDate=Дата отчета
#
# Report one
#
reportOne.pageTitle=Отчет по контейнеру {0}
ContainerReportView.processingMode.AUTOMATIC=автоматический
ContainerReportView.processingMode.AUTO_MANUAL=автоматический с ручным вмешательством
ContainerReportView.processingMode.MANUAL=ручной
ContainerReportView.processingFinish.NORMAL=завершена
ContainerReportView.processingFinish.SEMI=завершена с отклонениями от ТП
ContainerReportView.processingFinish.DISBAND=расформирована
#
# importIndex
# 
importIndex.pageTitle=Импорт/экспорт деталей
#
# import Details
#
importDetails.pageTitle=Импорт деталей
ImportDecision.IGNORE=пропустить
ImportDecision.INCOMPLETE=неполный
ImportDecision.FOUND=существует
ImportDecision.CREATE=создать
ImportDecision.UPDATE=обновить
#
# maintenance
#
maintenance.alert.severity.normal=Норма
maintenance.alert.severity.critical=Критично
maintenance.alert.severity.warn=Предупреждение
maintenance.alert.severity.warning=Предупреждение
#
#  ChangeContainerStep
#
ChangeContainerStep.completeNotice=Программа обновлена
ChangeContainerStep.title=Назначить шаг программы для контейнера {0}
ChangeContainerStep.noOptions=Нет вариантов для смены шага
#
# Native reports
#
reportNative.pageTitle=Шаблоны общих отчетов
reportNative.reportForm.report=Выберите отчет
reportNative.reportForm.reportHelp=
reportNative.reportForm.submit=Создать отчет
reportNative.reportForm.errorNotDefined=В форме отчета не определен запрос (query)
reportNative.reportForm.errorParameterIsEmpty=Необходимо задать параметр отчета
reportNative.status.PENDING=Ждет выборку
reportNative.status.RUNNING=Выполняется
reportNative.status.COMPLETED=Готов
reportNative.status.ERROR=Ошибка
#
# LockContainer
#
LockContainer.completeNotice=Контейнер заблокирован!
LockContainer.confirmNotice=Заблокировать контейнер {0}?
#
# UnlockContainer
#
UnlockContainer.completeNotice=Контейнер разблокирован!
UnlockContainer.confirmNotice=Разблокировать контейнер {0}?
#
# CloneProcess
#
CloneProcess.confirmBody=Будет создана копия процесса. Продолжить?
CloneProcess.completeNotice=Новый процесс создан
CloneProcess.confirmNotice=Клонирование процесса
#
# SendLoadedToStorage
#
SendLoadedToStorage.confirmNotice=Отправить контейнер {0} в накопитель?
SendLoadedToStorage.completeNotice=Контейнер отправлен в накопитель
#
#
#
prometheus.emptyResponse=данные отсутствуют
#
# About
#
about.pageTitle=О программе
#
# CloneContainer
# 
CloneContainer.title=Клонируем контейнер {0}...
CloneContainer.body=Это может занять некоторое время
#
# Backups
#
BackupService.deleteConfirm=Удалить файл резервной копии?
#  
# StartRectifier
#
StartRectifier.title=Запуск выпрямителя позиции {0}
StartRectifier.form.errorModeNotDefined=Не выбран режим запуска
StartRectifier.completeNotice=Выпрямитель запущен
StartRectifier.form.type.PROFILE=профиль
StartRectifier.form.type.BUILT_IN=встроенная программа
StartRectifier.form.type.CURRENT=значение тока
StartRectifier.form.type.VOLTAGE=значение напряжения
#
# Page information block
#
PageInformationBlock.noTitle={нет заголовка}
PageInformationBlock.noBody={нет содержимого}
PageInformationBlock.gotoEditor=редактировать в новом окне